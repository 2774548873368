import { Component } from '@angular/core';
import { monthOptions } from '../utils/helpers';
import { PaymentSlipService } from './services/payment-slip.service';
import { PaymentSlipResponseDTO } from '../interfaces/payment-slips.interface';
import { CompanyService } from '../services/company.service';
import { IComboBase } from '../interfaces/base/base.interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-payment-slips',
  templateUrl: './payment-slips.component.html',
  styleUrls: ['./payment-slips.component.scss'],
})
export class PaymentSlipsComponent {
  dataSource: PaymentSlipResponseDTO[] = [];
  mostrar: boolean = false;
  mostrarModalDetalle: boolean = false;
  dataDetail: any = null;
  // Opciones del combo
  monthOptions: IComboBase[] = monthOptions;
  currentMonth: IComboBase | null = null;

  isLoading: boolean = true;

  // Término de búsqueda
  searchTerm = '';

  // Mes seleccionado
  selectedMonth: IComboBase | null = null;
  userData: any = null;

  tabs: IComboBase[] = [];
  selectedTabKey: string | null = null;

  selectedDocument: SafeResourceUrl | null = null;

  constructor(
    private paymentSlipService: PaymentSlipService,
    private companyService: CompanyService,
    private sanitizer: DomSanitizer
  ) {
    this.currentMonth = this.getCurrentMonth();
    this.selectedMonth = this.currentMonth;
  }

  displayedColumns = [
    { key: 'paymentDocumentNumber', header: 'Nº Documento' },
    { key: 'paymentSlipAmount', header: 'Importe' },
    { key: 'issueDate', header: 'Fecha' },
    { key: 'employeeName', header: 'Empleado' },
    { key: 'employeeDocumentNumber', header: 'D.N.I.' },
  ];

  actionButtons = [
    {
      icon: 'pi pi-eye',
      color: '#ED672A',
      tooltip: 'Ver',
      action: (row: any) => {
        console.log('Ver:', row);
        this.dataDetail = {
          ...row,
          empresa: this.tabs.find((tab) => tab.key === this.selectedTabKey),
        };
        this.mostrarModalDetalle = true;
      },
      visible: (row: any) => true,
    },
  ];

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }

    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.filterData();
      },
      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
        this.filterData();
      },
    });
  }

  onNewRegister(): void {
    this.mostrar = true;
    console.log('click en nuevo');
  }

  onDialogDetailClose() {
    this.mostrarModalDetalle = false;
    this.dataDetail = null;
  }

  onMonthChange(selected: IComboBase): void {
    this.selectedMonth = selected;
    this.filterData(); // Aplicar filtro cuando cambia el mes
  }

  private getCurrentMonth(): IComboBase | null {
    const currentMonthKey = new Date().toISOString().slice(5, 7);
    return (
      this.monthOptions.find((option) => option.key === currentMonthKey) || null
    );
  }

  onSearch(query: string): void {
    this.searchTerm = query;
    this.filterData(); // Aplicar filtro cuando cambia la búsqueda
  }

  filterData(): void {
    this.isLoading = true;
    const filters = {
      search: this.searchTerm,
      month: this.selectedMonth?.key || null, // Si no hay mes seleccionado, enviar null
      employeeId: this.userData?.employeeId,
    };

    this.paymentSlipService.getAllPaymentSlips(filters).subscribe({
      next: (data: PaymentSlipResponseDTO[]) => {
        this.dataSource = data;
        console.log('Datos filtrados:', this.dataSource);
        this.isLoading = false;
      },
      error: (err: any) => {
        this.isLoading = false;
        console.error('Error al obtener los datos:', err);
      },
    });
  }

  onTabSelected(tabKey: string): void {
    this.selectedTabKey = tabKey;
    console.log('Tab seleccionado:', tabKey);
    this.selectedDocument = null;
    this.filterData();
    // Puedes agregar lógica adicional aquí, como actualizar los datos mostrados
  }

  onRowSelected(row: any): void {
    if (row && row.paymentSlipUrl) {
      // Agregar un parámetro único para forzar la recarga
      const uniqueUrl = `${
        row.paymentSlipUrl
      }?timestamp=${new Date().getTime()}`;
      this.selectedDocument =
        this.sanitizer.bypassSecurityTrustResourceUrl(uniqueUrl);
      console.log('Documento seleccionado:', this.selectedDocument);
    } else {
      this.selectedDocument = null;
    }
  }
}
