<p-dialog
  header="Ver Registro"
  [(visible)]="visible"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [ngStyle]="dialogStyle"
>
  <div class="loading-spinner" *ngIf="isLoading">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>
  <div class="flex flex-column gap-3 mb-3" *ngIf="!isLoading">
    <div class="field">
      <label for="empresa" class="font-semibold">Empresa</label>
      <p-autoComplete
        id="empresa"
        [ngModel]="selectedEmpresa"
        [disabled]="true"
        [style]="{ width: '100%' }"
        [field]="'text'"
      ></p-autoComplete>
    </div>
    <br />
    <div class="field">
      <label for="colaborador" class="font-semibold">Colaborador</label>
      <p-autoComplete
        id="colaborador"
        [ngModel]="selectedColaborador"
        [disabled]="true"
        [style]="{ width: '100%' }"
        [field]="'text'"
      ></p-autoComplete>
    </div>
    <br />
    <div class="field">
      <label for="moneda" class="font-semibold">Moneda</label>
      <p-autoComplete
        id="moneda"
        [ngModel]="selectedMoneda"
        [disabled]="true"
        [style]="{ width: '100%' }"
        [field]="'text'"
      ></p-autoComplete>
    </div>
    <br />
    <div class="field">
      <label for="monto" class="font-semibold">Monto</label>
      <p-inputNumber
        id="monto"
        [ngModel]="monto"
        [disabled]="true"
        mode="decimal"
        [style]="{ width: '100%' }"
      ></p-inputNumber>
    </div>
    <br />
    <div class="field">
      <label for="fecha" class="font-semibold">Fecha</label>
      <p-calendar
        id="fecha"
        [ngModel]="issueDate"
        [disabled]="true"
        [showIcon]="true"
        dateFormat="dd/mm/yy"
        [style]="{ width: '100%' }"
      ></p-calendar>
    </div>
    <br />
    <div class="switch-container">
      <label for="isPaid" class="font-semibold">¿Está pagado?</label>
      <p-inputSwitch
        id="isPaid"
        [ngModel]="isPaid"
        [disabled]="true"
      ></p-inputSwitch>
    </div>
    <br />
    <div class="field">
      <label for="documento" class="font-semibold">Documento (PDF)</label>
      <div class="custom-file-display">
        <span>{{ selectedFileName || "No disponible" }}</span>
        <i class="pi pi-file-pdf"></i>
      </div>
    </div>
  </div>
</p-dialog>
